const initState = {
  search: '',
  category: [],
  promo: false,
  cartCount: 0,
  categoryList: [],
  selectedCategory: [],
  categoryName: '',
  subCategoryName: '',
  categorySEODesc: '',
  categorySEOKey: '',
  subCategorySEODesc: '',
  subCategorySEOKey: '',
};

const SearchMyCatalogueReducer = (state = initState, action) => {
  switch (action.type) {
    case 'SET_SEARCH_MYCATALOGUE':
      return {
        ...state,
        search: action.payload.data,
      };
    case 'SET_PROMO_MYCATALOGUE':
      return {
        ...state,
        promo: action.payload.data,
      };
    case 'SET_CATAGORY_MYCATALOGUE':
      return {
        ...state,
        category: action.payload.data,
      };
    case 'SET_SELECTED_CATAGORY_MYCATALOGUE':
      return {
        ...state,
        selectedCategory: action.payload.data,
      };
    case 'SET_CART_COUNT':
      return {
        ...state,
        cartCount: action.payload.data,
      };
    case 'SET_CATEGORY_LIST':
      return {
        ...state,
        categoryList: action.payload.data,
      };
    case 'SET_CATEGORY_NAME':
      return {
        ...state,
        categoryName: action.payload.data,
      };
    case 'SET_SUBCATEGORY_NAME':
      return {
        ...state,
        subCategoryName: action.payload.data,
      };
    case 'SET_CATEGORY_SEO_DESC':
      return {
        ...state,
        categorySEODesc: action.payload.data,
      };
    case 'SET_SUBCATEGORY_SEO_DESC':
      return {
        ...state,
        subCategoryName: action.payload.data,
      };
    case 'SET_CATEGORY_SEO_KEY':
      return {
        ...state,
        categorySEOKey: action.payload.data,
      };
    case 'SET_SUBCATEGORY_SEO_KEY':
      return {
        ...state,
        subCategoryName: action.payload.data,
      };
    case 'RESET_SEARCH_STATE':
      return {
        ...state,
        ...initState,
      };
    default:
      return { ...state };
  }
};

export default SearchMyCatalogueReducer;
