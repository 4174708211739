
  
const initState = {
    switchAccount: [],
    page: 1,
    limit: 10,
    count: 0
  };
  
  const SwitchAccountReducer = (state = initState, action) => {
    switch (action.type) {
      case 'SET_SWITCH_ACCOUNT':
        return {
          ...state,
          switchAccount: action.payload.data,
          page: action.payload.page,
          limit: action.payload.limit,
          count: action.payload.count
        };
      case 'RESET_SWITCH_ACCOUNT_STATE':
        return {
          ...state,
          ...initState
        };
      default:
        return { ...state };
    }
  };
  
  export default SwitchAccountReducer;
  