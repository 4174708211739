
  
const initState = {
    news: [],
    page: 1,
    limit: 10,
    count: 0
  };
  
  const NewsReducer = (state = initState, action) => {
    switch (action.type) {
      case 'SET_NEWS':
        return {
          ...state,
          news: action.payload.data,
          page: action.payload.page,
          limit: action.payload.limit,
          count: action.payload.count
        };
      case 'RESET_STATE':
        return {
          ...state,
          ...initState
        };
      default:
        return { ...state };
    }
  };
  
  export default NewsReducer;
  