
  
const initState = {
    customerCompanyUsers: [],
    page: 1,
    limit: 10,
    count: 0
  };
  
  const CustomerCompanyUsersReducer = (state = initState, action) => {
    switch (action.type) {
      case 'SET_CUSTOMER_COMPANY_USERS':
        return {
          ...state,
          customerCompanyUsers: action.payload.data,
          page: action.payload.page,
          limit: action.payload.limit,
          count: action.payload.count
        };
      case 'RESET_STATE':
        return {
          ...state,
          ...initState
        };
      default:
        return { ...state };
    }
  };
  
  export default CustomerCompanyUsersReducer;
  