// import external modules
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

// import internal(own) modules
import rootReducer from '../reducers/rootReducer';

const middlewares = [thunk];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  {},
  composeEnhancers(applyMiddleware(...middlewares))
);

export { store };
