
  
const initState = {
    productCategory: [],
    page:1,
    limit : 10,
    totalCategories : 0
  };
  
  const productCategoryReducer = (state = initState, action) => {
    switch (action.type) {
      case 'SET_PRODUCT_CATEGORY':
        return {
          ...state,
          productCategory: action.payload.data,
          page: action.payload.page,
          limit : action.payload.limit,
          totalCategories: action.payload.totalCategories
        };
      case 'RESET_PRODUCT_CATEGORY':
        return {
          ...state,
          ...initState
        };
      default:
        return { ...state };
    }
  };
  
  export default productCategoryReducer;
  