
  
const initState = {
    stockSheet: [],
    page: 1,
    limit: 10,
    count: 0
  };
  
  const StockSheetReducer = (state = initState, action) => {
    switch (action.type) {
      case 'SET_STOCK_SHEET':
        return {
          ...state,
          stockSheet: action.payload.data,
          page: action.payload.page,
          limit: action.payload.limit,
          count: action.payload.count
        };
      case 'RESET_STOCK_SHEET_STATE':
        return {
          ...state,
          ...initState
        };
      default:
        return { ...state };
    }
  };
  
  export default StockSheetReducer;
  