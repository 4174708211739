import api from "../../../api/api";
export const setSearchMyCatalogue = (value) => {
  // console.log("Here", value);
  return {
    type: "SET_SEARCH_MYCATALOGUE",
    payload: { data: value },
  };
};

export const setCategoryMyCatalogue = (value) => {
  // console.log("Here Category", value);
  return {
    type: "SET_CATAGORY_MYCATALOGUE",
    payload: { data: value },
  };
};

export const setSelectedCategoryMyCatalogue = (value) => {
  // console.log("Here Category", value);
  return {
    type: "SET_SELECTED_CATAGORY_MYCATALOGUE",
    payload: { data: value },
  };
};

export const setPromoMyCatalogue = (value) => {
  // console.log("Here Category", value);
  return {
    type: "SET_PROMO_MYCATALOGUE",
    payload: { data: value },
  };
};

export const setCartCount = (value) => {
  // console.log("Here cart", value);
  return {
    type: "SET_CART_COUNT",
    payload: { data: value },
  };
};

export const setCategoryList = (value) => {
  // console.log("Here list", value);
  return {
    type: "SET_CATEGORY_LIST",
    payload: { data: value },
  };
};
export const setCategoryName = (value) => {
  // console.log("Here list", value);
  return {
    type: "SET_CATEGORY_NAME",
    payload: { data: value },
  };
};
export const setSubCategoryName = (value) => {
  // console.log("Here list", value);
  return {
    type: "SET_SUBCATEGORY_NAME",
    payload: { data: value },
  };
};
export const setCategorySEODesc = (value) => {
  // console.log("Here list", value);
  return {
    type: "SET_CATEGORY_SEO_DESC",
    payload: { data: value },
  };
};
export const setSubCategorySEODesc = (value) => {
  // console.log("Here list", value);
  return {
    type: "SET_SUBCATEGORY_SEO_DESC",
    payload: { data: value },
  };
};
export const setCategorySEOKey = (value) => {
  // console.log("Here list", value);
  return {
    type: "SET_CATEGORY_SEO_KEY",
    payload: { data: value },
  };
};
export const setSubCategorySEOKey = (value) => {
  // console.log("Here list", value);
  return {
    type: "SET_SUBCATEGORY_SEO_KEY",
    payload: { data: value },
  };
};
