
  
const initState = {
    quotes: [],
    page: 1,
    limit: 10,
    count: 0,
    quoteDetail: []
  };
  
  const QuoteReducer = (state = initState, action) => {
    switch (action.type) {
      case 'SET_QUOTE':
        return {
          ...state,
          quotes: action.payload.data,
          page: action.payload.page,
          limit: action.payload.limit,
          count: action.payload.count
        };
      case 'SET_QUOTE_DETAIL':
        return {
          ...state,
          quoteDetail: action.payload.data
        };
      case 'RESET_QUOTE_STATE':
        return {
          ...state,
          ...initState
        };
      default:
        return { ...state };
    }
  };
  
  export default QuoteReducer;
  