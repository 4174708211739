
  
const initState = {
    openOrder: [],
    page: 1,
    limit: 10,
    count: 0,
    orderDetail:[]
  };
  
  const OpenOrderReducer = (state = initState, action) => {
    switch (action.type) {
      case 'SET_OPENORDER':
        return {
          ...state,
          openOrder: action.payload.data,
          page: action.payload.page,
          limit: action.payload.limit,
          count: action.payload.count
        };
      case 'SET_ORDERDETAIL':
        return {
          ...state,
          orderDetail: action.payload.data
        };
      case 'RESET_OPENORDER_STATE':
        return {
          ...state,
          ...initState
        };
      default:
        return { ...state };
    }
  };
  
  export default OpenOrderReducer;
  