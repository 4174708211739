
  
const initState = {
    company: [],
  };
  
  const CompanyReducer = (state = initState, action) => {
    switch (action.type) {
      case 'SET_COMPANY':
        return {
          ...state,
          company: action.payload.data,
        };
      case 'RESET_COMPANY_STATE':
        return {
          ...state,
          ...initState
        };
      default:
        return { ...state };
    }
  };
  
  export default CompanyReducer;
  