
  
const initState = {
    trainingItem: [],
    page: 1,
    limit: 10,
    count: 0
  };
  
  const TrainingItemReducer = (state = initState, action) => {
    switch (action.type) {
      case 'SET_TRAINING_ITEM':
        return {
          ...state,
          trainingItem: action.payload.data,
          page: action.payload.page,
          limit: action.payload.limit,
          count: action.payload.count
        };
      case 'RESET_STATE_TRAINING_ITEM':
        return {
          ...state,
          ...initState
        };
      default:
        return { ...state };
    }
  };
  
  export default TrainingItemReducer;
  