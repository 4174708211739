export const setMyCatalogue = (value, page, limit, count) => {
    return {
      type: 'SET_MYCATALOGUE',
      payload: { data: value, page, limit, count }
    };
  };

  export const setCatalogueDetail = (value) => {
    return {
      type: 'SET_CATALOGUE_DETAIL',
      payload: { data: value }
    };
  };