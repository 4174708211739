
  
const initState = {
    myCatalogue: [],
    page: 1,
    limit: 10,
    count: 0,
    catalogueDetail : {}
  };
  
  const MyCatalogueReducer = (state = initState, action) => {
    switch (action.type) {
      case 'SET_MYCATALOGUE':
        return {
          ...state,
          myCatalogue: action.payload.data,
          page: action.payload.page,
          limit: action.payload.limit,
          count: action.payload.count
        };
        case 'SET_CATALOGUE_DETAIL':
          return {
            ...state,
            catalogueDetail : action.payload.data
          };
      case 'RESET_MYCATALOGUE':
        return {
          ...state,
          ...initState
        };
      default:
        return { ...state };
    }
  };
  
  export default MyCatalogueReducer;
  