
  
const initState = {
    marketingCategory: []
  };
  
  const MarketingCategoryReducer = (state = initState, action) => {
    switch (action.type) {
      case 'SET_MARKETING_CATEGORY':
        return {
          ...state,
          marketingCategory: action.payload.data
        };
      case 'RESET_MARKETING_CATEGORY_STATE':
        return {
          ...state,
          ...initState
        };
      default:
        return { ...state };
    }
  };
  
  export default MarketingCategoryReducer;
  