import { api } from '../../../api/api';
import { setNews } from '../news/newsAction';
import { setPromotion, setActivePromotion } from '../promotion/promotionAction';
import { setSubscriber } from '../subscriber/subscriberAction';
import { toastr } from 'react-redux-toastr';
import { setLasherCatalogue } from '../lasherCatalogue/lasherCatalogueAction';
import { setMarketingCategory } from '../marketingCategory/marketingCategoryAction';
import { setTrainingCategory } from '../trainingCategory/trainingCategoryAction';
import { setMarketingItem } from '../marketingItem/marketingItemAction';
import { setTrainingItem } from '../trainingItem/trainingItemAction';
import { setAuditTrail } from '../auditTrail/auditTrailAction';
import { setStockSheet } from '../stockSheet/stockSheetAction';
import { setRelation } from '../relation/relationAction';
import { setCompany } from '../company/companyAction';
import { setCompanyUsers } from '../companyUsers/companyUsersAction';
import { setRepContactAccount } from '../repContactAccounts/repContactAccount';
import { setRepContact } from '../repContact/repContact';
import { setSwitchAccount } from '../switchAccount/switchAccountAction';
import {
  setMyCatalogue,
  setCatalogueDetail,
} from '../myCatalogue/myCatalogueAction';
import { setOpenOrder, setOrderDetail } from '../openOrder/openOrderAction';
import { setBackOrder } from '../backOrder/backOrderAction';
import { setInvoice, setInvoiceDetail } from '../invoice/invoiceAction';
import { setCart } from '../cart/cartAction';
import { setAdminDashboard } from '../adminDashboard/adminDashboardAction';
import {
  setCartCount,
  setCategoryList,
} from '../searchMyCatalogue/searchMyCatalogueAction';
import { setProductCategory } from '../productCategory/productCategoryAction';
import { setQuote, setQuoteDetail } from '../quote/quoteAction';
import history from '../../../app/history';

export const sendContactQueryApi = async (data) => {
  let response = await api(`/send-query`, data, 'post');
  if (response && response.status === 200) {
    toastr.success('Message sent successfully');
    history.push('/');
  } else {
    toastr.error('Message not sent. Please try after sometime');
  }
};

export const getOrderDetailApi = (code) => {
  return async (dispatch) => {
    let response = await api(
      `/getInvoiceDetailsByCode/${code}?documentType=2`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      dispatch(setOrderDetail(response.data.data));
    } else {
      toastr.info('Details not found. Please try after sometime');
      history.push('/invoices');
      dispatch(setOrderDetail([]));
    }
  };
};

export const getQuoteDetailApi = (code) => {
  return async (dispatch) => {
    let response = await api(
      `/getInvoiceDetailsByCode/${code}?documentType=1`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      dispatch(setQuoteDetail(response.data.data));
    } else {
      toastr.info('Details not found. Please try after sometime');
      history.push('/invoices');
      dispatch(setQuoteDetail([]));
    }
  };
};

export const QuoteApi = (
  code,
  search = '',
  page = 1,
  limit = 10,
  start = 0,
  end = 0
) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };

    let response = await api(
      `/getInvoiceByCode/${code}?limit=${data.limit}&page=${page}&search=${search}`,
      { start, end, documentType: '1' },
      'post'
    );
    // console.log(response, 'response api');
    if (response && response.status === 200) {
      dispatch(
        setQuote(response.data.data, page, limit, response.data.totalInvoices)
      );
    } else {
      dispatch(setQuote([]));
    }
  };
};

export const processQuoteB2CApi = (data) => {
  return async (dispatch) => {
    let response = await api(`/process-cart-b2c`, data, 'post');
    if (response && response.status === 200) {
      toastr.success(`Quote processed Successfully`);
      localStorage.setItem('HandyCart', JSON.stringify([]));
      history.push('/');
    } else {
      toastr.error(
        `Quote cannot be processed at the moment. Please try after sometime`
      );
    }
  };
};
export const priceUpdateQuoteB2CApi = (data) => {
  return async (dispatch) => {
    let response = await api(`/update-cart-b2c`, data, 'post');
    if (response && response.status === 200) {
      return response;
      // toastr.success(`Quote processed Successfully`);
      // localStorage.setItem('HandyCart', JSON.stringify([]));
      // history.push('/');
    } else {
      toastr.error(`Something went wrong`);
      return null;
    }
  };
};

export const resetMyCatalogue = () => {
  return async (dispatch) => {
    dispatch(setMyCatalogue([]));
  };
};

export const syncInvoiceApi = async (code) =>
  await api(`/sync-invoice/${code}`, {}, 'get');

export const getInvoiceDetailApi = (code) => {
  return async (dispatch) => {
    let response = await api(
      `/getInvoiceDetailsByCode/${code}?documentType=3`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      dispatch(setInvoiceDetail(response.data.data));
    } else {
      toastr.info('Details not found. Please try after sometime');
      history.push('/invoices');
      dispatch(setInvoiceDetail([]));
    }
  };
};

export const CatalogueDetailApi = (slug, type) => {
  return async (dispatch) => {
    let store = localStorage.getItem('B2BStore');
    let query = '';
    if (!store) {
      store = 'JHB';
    }
    if (type === 'id') {
      query = `/getById/${slug}?store=${store}`;
    } else {
      query = `/getBySlug/${slug}?store=${store}`;
    }
    let response = await api(query, {}, 'get');
    if (response && response.status === 200) {
      // console.log(response, 'response');
      dispatch(setCatalogueDetail(response.data.data));
      return response;
    } else {
      history.push('/');
      dispatch(setCatalogueDetail({}));
      return response;
    }
  };
};

export const resetCatalogueDetail = () => {
  return async (dispatch) => {
    dispatch(setCatalogueDetail({}));
  };
};

export const assignItemsToCategoryApi = async (data) =>
  await api(`/item/assignCategory`, data, 'post');

export const productCategoryApi = (page = 1, limit = 10) => {
  return async (dispatch) => {
    let response = await api(
      `/productCategory?limit=${limit}&page=${page}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      dispatch(
        setProductCategory(
          response.data.data,
          response.data.page,
          response.data.limit,
          response.data.totalCategories
        )
      );
    } else {
      dispatch(setProductCategory([]));
    }
    return response;
  };
};

export const newsApi = (search = '', page = 1, limit = 10) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };

    let response = await api(
      `/news?limit=${data.limit}&page=${page}&search=${search}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      dispatch(
        setNews(response.data.data, page, limit, response.data.totalNews)
      );
    } else {
      dispatch(setNews([]));
    }
  };
};

export const promotionApi = (search = '', page = 1, limit = 10) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };
    let response = await api(
      `/promotion?limit=${data.limit}&page=${page}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      // let countResponse = await api('/admin/count',{},'get');
      // if(countResponse.status === 200){
      dispatch(
        setPromotion(
          response.data.data.products,
          page,
          limit,
          response.data.totalPromos,
          response.data.data.promos
        )
      );
      // }
    } else {
      dispatch(setPromotion([]));
    }
  };
};

export const marketingItemApi = (
  search = '',
  page = 1,
  limit = 10,
  sort = 'createdAt DESC'
) => {
  return async (dispatch) => {
    const data = {
      page,
      limit,
    };
    let response = await api(
      `/doc/items?limit=${data.limit}&page=${page}&search=${search}&sort=${sort}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      // let countResponse = await api('/admin/count',{},'get');
      // if(countResponse.status === 200){
      dispatch(
        setMarketingItem(
          response.data.data,
          page,
          limit,
          response.data.totalArticles
        )
      );
      // }
    } else {
      dispatch(setMarketingItem([]));
    }
  };
};

export const trainingItemApi = (
  search = '',
  page = 1,
  limit = 10,
  sort = 'createdAt DESC'
) => {
  return async (dispatch) => {
    const data = {
      page,
      limit,
    };
    let response = await api(
      `/items?type=training&limit=${data.limit}&page=${page}&search=${search}&sort=${sort}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      // let countResponse = await api('/admin/count',{},'get');
      // if(countResponse.status === 200){
      dispatch(
        setTrainingItem(
          response.data.data,
          page,
          limit,
          response.data.totalArticles
        )
      );
      // }
    } else {
      dispatch(setTrainingItem([]));
    }
  };
};

export const trainingItemByCategoryApi = (
  search = '',
  page = 1,
  limit = 10,
  categories = [],
  sort = 'createdAt DESC'
) => {
  return async (dispatch) => {
    const data = {
      page,
      limit,
    };
    let response = await api(
      `/itemsByCategory?type=training&limit=${data.limit}&page=${page}&search=${search}&sort=${sort}`,
      { categories },
      'post'
    );
    if (response && response.status === 200) {
      // let countResponse = await api('/admin/count',{},'get');
      // if(countResponse.status === 200){
      dispatch(
        setTrainingItem(
          response.data.data,
          page,
          limit,
          response.data.totalArticles
        )
      );
      // }
    } else {
      dispatch(setTrainingItem([]));
    }
  };
};

export const marketingItemByCategoryApi = (
  search = '',
  page = 1,
  limit = 10,
  categories = [],
  sort = 'createdAt DESC'
) => {
  return async (dispatch) => {
    const data = {
      page,
      limit,
    };
    let response = await api(
      `/itemsByCategory?type=marketing&limit=${data.limit}&page=${page}&search=${search}&sort=${sort}`,
      { categories },
      'post'
    );
    if (response && response.status === 200) {
      // let countResponse = await api('/admin/count',{},'get');
      // if(countResponse.status === 200){
      dispatch(
        setMarketingItem(
          response.data.data,
          page,
          limit,
          response.data.totalArticles
        )
      );
      // }
    } else {
      dispatch(setMarketingItem([]));
    }
  };
};

export const marketingCategoryApi = () => {
  return async (dispatch) => {
    let response = await api(`/category?type=OTHERS`, {}, 'get');
    if (response && response.status === 200) {
      // let countResponse = await api('/admin/count',{},'get');
      // if(countResponse.status === 200){
      dispatch(setMarketingCategory(response.data.data));
      // }
    } else {
      dispatch(setMarketingCategory([]));
    }
  };
};

export const trainingCategoryApi = () => {
  return async (dispatch) => {
    let response = await api(`/category?type=training`, {}, 'get');
    if (response && response.status === 200) {
      // let countResponse = await api('/admin/count',{},'get');
      // if(countResponse.status === 200){
      dispatch(setTrainingCategory(response.data.data));
      // }
    } else {
      dispatch(setTrainingCategory([]));
    }
  };
};

export const activePromotionApi = () => {
  return async (dispatch) => {
    let response = await api(`/activePromotion`, {}, 'get');
    if (response && response.status === 200) {
      // let countResponse = await api('/admin/count',{},'get');
      // if(countResponse.status === 200){
      dispatch(setActivePromotion(response.data.data[0]));
      // }
    } else {
      dispatch(setActivePromotion({}));
    }
  };
};

export const subscriberApi = (search = '', page = 1, limit = 10) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };
    let response = await api(
      `/subscriber?limit=${data.limit}&page=${page}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      // let countResponse = await api('/admin/count',{},'get');
      // if(countResponse.status === 200){
      dispatch(
        setSubscriber(
          response.data.data,
          page,
          limit,
          response.data.totalSubscribers
        )
      );
      // }
    } else {
      dispatch(setSubscriber([]));
    }
  };
};

export const lasherCatalogueApi = (
  search = '',
  page = 1,
  limit = 20,
  sort = 'createdAt,-1',
  filter = { category: [], industry: [], brand: [] }
) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };
    let response = await api(
      `/items?limit=${data.limit}&page=${page}&search=${search}&field=${
        sort.split(',')[0]
      }&direction=${sort.split(',')[1]}`,
      { filter },
      'get'
    );
    if (response && response.status === 200) {
      // let countResponse = await api('/admin/count',{},'get');
      // if(countResponse.status === 200){
      dispatch(
        setLasherCatalogue(
          response.data.data,
          page,
          limit,
          response.data.totalProducts
        )
      );
      // }
    } else {
      dispatch(setLasherCatalogue([]));
    }
  };
};

export const myCatalogueApi = (
  id,
  search = '',
  page = 1,
  limit = 10,
  sort = 'createdAt DESC',
  filter = { category: [], industry: [], brand: [], promo: [] }
) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };
    let response = await api(
      `/item/getByFilters?page=${page}&limit=${limit}&sort=${sort}&search=${search}`,
      { categories: filter.category, promo: filter.promo },
      'post'
    );
    if (response && response.status === 200) {
      // console.log(response, 'response');
      dispatch(
        setMyCatalogue(
          response.data.data,
          page,
          limit,
          response.data.totalProducts
        )
      );
    } else {
      dispatch(setMyCatalogue([]));
    }
  };
};

export const auditTrailApi = (search = '', page = 1, limit = 10) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };
    let response = await api(
      `/audit?limit=${data.limit}&page=${page}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      // let countResponse = await api('/admin/count',{},'get');
      // if(countResponse.status === 200){
      dispatch(
        setAuditTrail(
          response.data.data,
          page,
          limit,
          response.data.totalAudits
        )
      );
      // }
    } else {
      dispatch(setAuditTrail([]));
    }
  };
};

export const StockSheetApi = (id, search = '', page = 1, limit = 10) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };
    let response = await api(
      `/stocksheetsByAccount/${id}?limit=${data.limit}&page=${page}&search=${search}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      // let countResponse = await api('/admin/count',{},'get');
      // if(countResponse.status === 200){
      dispatch(
        setStockSheet(
          response.data.data,
          page,
          limit,
          response.data.totalStockSheets
        )
      );
      // }
    } else {
      dispatch(setStockSheet([]));
    }
  };
};

export const RelationApi = (search = '', page = 1, limit = 10) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };
    let response = await api(
      `/relatedProducts?limit=${data.limit}&page=${page}&search=${search}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      dispatch(
        setRelation(
          response.data.data,
          page,
          limit,
          response.data.totalProducts
        )
      );
      // }
    } else {
      dispatch(setRelation([]));
    }
  };
};

export const CompanyUsersApi = (search = '', page = 1, limit = 10) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };
    let response = await api(
      `/user?limit=${data.limit}&page=${page}&search=${search}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      dispatch(
        setCompanyUsers(
          response.data.data,
          page,
          limit,
          response.data.totalUsers
        )
      );
      // }
    } else {
      dispatch(setCompanyUsers([]));
    }
  };
};

export const CustomerCompanyUsersApi = (
  id,
  search = '',
  page = 1,
  limit = 10
) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };
    let response = await api(
      `/userByAccount/${id}?limit=${data.limit}&page=${page}&search=${search}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      if (response.data.data.length > 0)
        dispatch(
          setCompanyUsers(
            response.data.data,
            page,
            limit,
            response.data.totalUsers
          )
        );
      else dispatch(setCompanyUsers([]));
      // }
    } else {
      dispatch(setCompanyUsers([]));
    }
  };
};

export const CompanyApi = (page = 1, search = '') => {
  return async (dispatch) => {
    // console.log('Here');
    let response = await api(
      `/accounts?page=${page}&limit=${10}&searchKey=${search}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      dispatch(setCompany(response.data.data));
      return response.data;
      // }
    } else {
      dispatch(setCompany([]));
      return {};
    }
  };
};

export const RepContactAccount = (id, search = '', page = 1, limit = 10) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };
    let response = await api(
      `/accountsForRep/${id}?limit=${data.limit}&page=${page}&search=${search}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      if (response.data.data.length > 0)
        dispatch(
          setRepContactAccount(
            response.data.data,
            page,
            limit,
            response.data.totalAccounts
          )
        );
      else dispatch(setRepContactAccount([]));
      // }
    } else {
      dispatch(setRepContactAccount([]));
    }
  };
};

export const RepContact = (id, search = '', page = 1, limit = 10) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };
    let response = await api(
      `/repContactsByAccount/${id}?limit=${data.limit}&page=${page}&search=${search}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      if (response.data.data.length > 0)
        dispatch(
          setRepContact(
            response.data.data,
            page,
            limit,
            response.data.totalRepContacts
          )
        );
      else dispatch(setRepContact([]));
      // }
    } else {
      dispatch(setRepContact([]));
    }
  };
};

export const SwitchAccountApi = (
  userId,
  accountId,
  search = '',
  page = 1,
  limit = 10
) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };
    let response = await api(
      `/accountByUser/${userId}/${accountId}?limit=${data.limit}&page=${page}&search=${search}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      if (response.data.data.length > 0)
        dispatch(
          setSwitchAccount(
            response.data.data,
            page,
            limit,
            response.data.totalAccounts
          )
        );
      else dispatch(setSwitchAccount([]));
      // }
    } else {
      dispatch(setSwitchAccount([]));
    }
  };
};

export const AdminSwitchAccountApi = (search = '', page = 1, limit = 10) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };
    let response = await api(
      `/accounts?limit=${data.limit}&page=${page}&searchKey=${search}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      if (response.data.data.length > 0)
        dispatch(
          setSwitchAccount(
            response.data.data,
            page,
            limit,
            response.data.totalAccounts
          )
        );
      else dispatch(setSwitchAccount([]));
      // }
    } else {
      dispatch(setSwitchAccount([]));
    }
  };
};

export const BackOrderApi = (id, search = '', page = 1, limit = 10) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };

    let response = await api(
      `/uniqueOrders/${id}?backOrder=true&limit=${data.limit}&page=${page}&search=${search}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      dispatch(
        setBackOrder(response.data.data, page, limit, response.data.totalOrders)
      );
    } else {
      dispatch(setBackOrder([]));
    }
  };
};

export const OpenOrderApi = (
  code,
  search = '',
  page = 1,
  limit = 10,
  start = 0,
  end = 0
) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };

    let response = await api(
      `/getInvoiceByCode/${code}?limit=${data.limit}&page=${page}&search=${search}`,
      { start, end, documentType: '2' },
      'post'
    );
    // console.log(response, 'response api');
    if (response && response.status === 200) {
      dispatch(
        setOpenOrder(
          response.data.data,
          page,
          limit,
          response.data.totalInvoices
        )
      );
    } else {
      dispatch(setOpenOrder([]));
    }
  };
};

// export const OpenOrderApi = (id, search = '', page = 1, limit = 10) => {
//   return async (dispatch) => {
//     const data = {
//       search,
//       page,
//       limit,
//     };

//     let response = await api(
//       `/uniqueOrders/${id}?backOrder=false&limit=${data.limit}&page=${page}&search=${search}`,
//       {},
//       'get'
//     );
//     if (response && response.status === 200) {
//       dispatch(
//         setOpenOrder(response.data.data, page, limit, response.data.totalOrders)
//       );
//     } else {
//       dispatch(setOpenOrder([]));
//     }
//   };
// };
export const OrdersApi = (search = '', page = 1, limit = 10) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };

    let response = await api(
      `/orders?backOrder=false&limit=${data.limit}&page=${page}&search=${search}`,
      {},
      'get'
    );
    if (response && response.status === 200) {
      dispatch(
        setOpenOrder(response.data.data, page, limit, response.data.totalOrders)
      );
    } else {
      dispatch(setOpenOrder([]));
    }
  };
};

export const InvoiceApi = (
  code,
  search = '',
  page = 1,
  limit = 10,
  start = 0,
  end = 0
) => {
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };

    let response = await api(
      `/getInvoiceByCode/${code}?limit=${data.limit}&page=${page}&search=${search}`,
      { start, end, documentType: '3' },
      'post'
    );
    // console.log(response, 'response api');
    if (response && response.status === 200) {
      dispatch(
        setInvoice(response.data.data, page, limit, response.data.totalInvoices)
      );
    } else {
      dispatch(setInvoice([]));
    }
  };
};

export const CartApi = (id) => {
  return async (dispatch) => {
    let response = JSON.parse(localStorage.getItem('HandyCart'));
    // console.log('response', response);
    if (response) {
      dispatch(setCart(response));
      dispatch(setCartCount(response.length ? response.length : 0));
    } else {
      dispatch(setCart([]));
      dispatch(setCartCount(0));
    }
  };
};

export const AdminDashboardApi = () => {
  return async (dispatch) => {
    // console.log('Here');
    let response = await api(`/dashboard`, {}, 'get');
    if (response && response.status === 200) {
      dispatch(setAdminDashboard(response.data.data));
      // }
    } else {
      dispatch(setAdminDashboard({}));
    }
  };
};

export const CategoryListApi = () => {
  return async (dispatch) => {
    // console.log('Here');
    let response = await api(`/productsCategories`, {}, 'get');
    if (response && response.status === 200) {
      let array = [];
      response.data.data.map((one) => {
        array.push({ id: one.id, title: one.title });
      });

      dispatch(setCategoryList(array));
    } else {
      dispatch(setCategoryList([]));
    }
  };
};

// export const transactionApi = (search = "", page = 1, limit = 10) => {
//   return async dispatch => {
//     const data = {
//       search,
//       page,
//       limit
//     };
//     let skip=(page-1)*limit;
//     // const artistResult = await api("admin/get/artist", data, "post");
//     let response={};
//     if(skip > 0 ){
//       response = await api(`/payment/receipts?limit=${data.limit}&skip=${skip}`,{}, 'get');
//     }
//     else{
//       response = await api(`/payment/receipts?limit=${data.limit}`,{}, 'get');
//     }
//     if (response && response.status === 200) {
//       // let countResponse = await api('/admin/count',{},'get');
//       // if(countResponse.status === 200){
//         // console.log(response.data.count);
//         dispatch(
//           setTransactions(
//               response.data.data,
//             page,
//             limit,
//             response.data.count
//           )
//         );
//       // }
//     } else {
//       dispatch(setTransactions([]));
//     }
//   };
// };

// export const serviceProviderApi = (search = "", page = 1, limit = 10) => {
//   return async dispatch => {
//     console.log("page",page);
//     const data = {
//       search,
//       page,
//       limit
//     };
//     let skip=(page-1)*limit;
//     // const artistResult = await api("admin/get/artist", data, "post");
//     let response={};
//     if(skip > 0 ){
//       response = await api(`/auth/firebase/admin/user?role=service-provider&limit=${data.limit}&skip=${skip}`,{}, 'get');
//     }
//     else{
//       response = await api(`/auth/firebase/admin/user?role=service-provider&limit=${data.limit}`,{}, 'get');
//     }
//     if (response && response.status === 200) {
//         dispatch(
//           setServiceProviders(
//               response.data.data.user,
//             page,
//             limit,
//             response.data.data.count
//           )
//         );
//     } else {
//       dispatch(setServiceProviders([]));
//     }
//   };
// };

// export const pendingEnquiryApi = (search = "", page = 1, limit = 10) => {
//   return async dispatch => {
//     console.log("page",page);
//     const data = {
//       search,
//       page,
//       limit
//     };
//     let skip=(page-1)*limit;
//     // const artistResult = await api("admin/get/artist", data, "post");
//     let response={};
//     if(skip > 0 ){
//       response = await api(`/auth/firebase/admin/status/user?verificationStatus=pending&limit=${data.limit}&skip=${skip}`,{}, 'get');
//     }
//     else{
//       response = await api(`/auth/firebase/admin/status/user?verificationStatus=pending&limit=${data.limit}`,{}, 'get');
//     }
//     if (response && response.status === 200) {
//         dispatch(
//           setPendingEnquiries(
//               response.data.data.user,
//             page,
//             limit,
//             response.data.data.count
//           )
//         );
//     } else {
//       dispatch(setPendingEnquiries([]));
//     }
//   };
// };

// export const categoryApi = () => {
//   return async dispatch => {

//     let  response = await api(`/order/category`,{}, 'get');
//     if (response && response.status === 200) {
//         dispatch(
//           setCategories(
//               response.data.data
//           )
//         );
//     } else {
//       dispatch(setCategories([]));
//     }
//   };
// };

export const updateNews = async (id, data) =>
  await api(`/news/${id}`, data, 'patch');

export const addNews = async (data) => {
  try {
    let response = await api(`/news`, data, 'post');
    return response;
  } catch (error) {
    toastr.error('Something Went Wrong. Try Again');
  }
};

export const deleteNews = async (id) => await api(`/news/${id}`, {}, 'delete');

export const updatePromotion = async (id, data) =>
  await api(`/promotion/${id}`, data, 'patch');

export const updatePromotionStatus = async (id, data) =>
  await api(`/promotion/${id}`, data, 'patch');

export const addPromotion = async (data) => {
  try {
    let response = await api(`/promotion`, data, 'post');
    return response;
  } catch (error) {
    toastr.error('Something Went Wrong. Try Again');
  }
};

export const deletePromotion = async (id, data) =>
  await api(`/promotion/${id}`, { products: data }, 'delete');

export const updateSubscriber = async (id, data) =>
  await api(`/subscriber/${id}`, data, 'patch');

export const addSubscriber = async (data) => {
  try {
    let response = await api(`/subscriber`, data, 'post');
    return response;
  } catch (error) {
    toastr.error('Something Went Wrong. Try Again');
  }
};

export const deleteSubscriber = async (id) =>
  await api(`/subscriber/${id}`, {}, 'delete');

export const createCategory = async (data) =>
  await api(`/category`, data, 'post');

export const updateCategory = async (id, data) =>
  await api(`/category/${id}`, data, 'patch');

export const deleteCategory = async (id) =>
  await api(`/category/${id}`, {}, 'delete');
export const getCategoryApi = async (id) =>
  await api(`/category/${id}`, {}, 'get');

export const deleteOldCategory = async (categoryId, subCategoriesId) =>
  await api(`/categoryUnchecked`, { categoryId, subCategoriesId }, 'delete');

export const deleteItem = async (id) =>
  await api(`/doc/items/${id}`, {}, 'delete');
export const createItem = async (data) => await api(`/doc/items`, data, 'post');
export const updateItem = async (id, data) =>
  await api(`/doc/items/${id}`, data, 'patch');

export const updateProductImage = async (id, image) =>
  await api(`/item/${id}`, { image: image }, 'patch');

export const updateProductDescription = async (id, data) =>
  await api(`/item/${id}`, data, 'patch');

export const customerRegistration = async (data) =>
  await api(`/customerAccount`, data, 'post');

export const mailRegisteredCustomer = async (data) =>
  await api(`/sendCustomerRegistrationForm`, { id: data }, 'post');

export const SubmitStandForm = async (data) =>
  await api(`/sendEventStandUpForm`, data, 'post');

export const SubmitStandAgreementForm = async (data) =>
  await api(`/sendStandAgreementForm`, data, 'post');

export const createStockSheet = async (data) =>
  await api(`/stocksheets`, data, 'post');

export const deleteStockSheet = async (id) =>
  await api(`/stocksheets/${id}`, {}, 'delete');

export const updateStockSheet = async (data, id) =>
  await api(`/stocksheets/${id}`, data, 'patch');

export const getAllProducts = async () => await api(`/allproducts`, {}, 'get');

export const createRelation = async (data) =>
  await api(`/relatedProducts`, data, 'post');

export const updateRelation = async (data, id) =>
  await api(`/relatedProducts/${id}`, data, 'patch');

export const deleteRelation = async (id) =>
  await api(`/relatedProducts/${id}`, {}, 'delete');

export const createUser = async (data) => await api(`/user`, data, 'post');

export const updateUser = async (id, data) =>
  await api(`/user/${id}`, data, 'patch');

export const deleteUser = async (id, account) =>
  await api(`/user/${id}`, { account: account }, 'delete');

export const createRepContact = async (data) =>
  await api(`/createRepContacts`, data, 'post');

export const updateCart = async (id, data) => {
  let store = localStorage.getItem('B2BStore');
  let response = await api(`/carts/${id}?store=${store}`, data, 'patch');
  // console.log(response, 'updatedCart');
  return response;
};

export const postOrder = async (data) => {
  // console.log(data);
  let response = await api(`/orderProcess`, data, 'post');
  return response;
};
export const sendProductData = async (id, data) => {
  let response = await api(
    `/sendProductDetail`,
    { account: id, details: data },
    'post'
  );
  return response;
};

export const resendPassword = async (email) => {
  let response = await api(`/resendPassword`, { email: email }, 'post');
  return response;
};

export const getProductIdCart = async (account, code) => {
  let response = await api(
    `/productsWithPricing/${account}/product/${code}`,
    {},
    'get'
  );
  return response;
};

export const stocksheetToCart = async (account, id) => {
  let store = localStorage.getItem('B2BStore');
  let response = await api(
    `/stocksheets/${id}/${account}?store=${store}`,
    {},
    'get'
  );
  // console.log(response, 'stocksheetToCart');
  return response;
};

export const allRepContact = (
  search = '',
  page = 1,
  limit = 10,
  start = 0,
  end = 0
) => {
  let date = new Date(start).getTime();
  // console.log(date);
  return async (dispatch) => {
    const data = {
      search,
      page,
      limit,
    };
    let response = await api(
      `/repContacts?limit=${data.limit}&page=${page}&search=${search}`,
      { start, end },
      'post'
    );
    // console.log(response);
    if (response && response.status === 200) {
      if (response.data.data.length > 0)
        dispatch(
          setRepContact(
            response.data.data,
            page,
            limit,
            response.data.totalRepContacts
          )
        );
      else dispatch(setRepContact([]));
      // }
    } else {
      dispatch(setRepContact([]));
    }
  };
};

export const updateAccountApi = (id) => {
  return async (dispatch) => {
    let response = await api(`/accountFromSyspro/${id}`, {}, 'patch');
    // if(respon)
  };
};

export const printStocksheet = (id, accountId) => {
  return async (dispatch) => {
    let response = await api(`/printStocksheet/${id}/${accountId}`, {}, 'get');
    return response;
  };
};
export const printCart = (id) => {
  return async (dispatch) => {
    let response = await api(`/printCart/${id}`, {}, 'get');
    return response;
  };
};
export const printProducts = (id, products) => {
  return async (dispatch) => {
    let response = await api(
      `/productsPrint/${id}`,
      { products: products },
      'post'
    );
    // console.log(response);
    return response;
  };
};
export const CategoryBySlugApi = (slug) => {
  return async (dispatch) => {
    let response = await api(`category-by-slug`, { slug }, 'post');
    return response;
  };
};
