
  
const initState = {
    relation: [],
    page: 1,
    limit: 10,
    count: 0
  };
  
  const RelationReducer = (state = initState, action) => {
    switch (action.type) {
      case 'SET_RELATION':
        return {
          ...state,
          relation: action.payload.data,
          page: action.payload.page,
          limit: action.payload.limit,
          count: action.payload.count
        };
      case 'RESET_RELATION_STATE':
        return {
          ...state,
          ...initState
        };
      default:
        return { ...state };
    }
  };
  
  export default RelationReducer;
  