
  
const initState = {
    adminDashboard:{}
  };
  
  const AdminDashboardReducer = (state = initState, action) => {
    switch (action.type) {
      case 'SET_ADMIN_DASHBOARD':
        return {
          ...state,
          adminDashboard: action.payload.data,
        };
      case 'RESET_ADMIN_DASHBOARD_STATE':
        return {
          ...state,
          ...initState
        };
      default:
        return { ...state };
    }
  };
  
  export default AdminDashboardReducer;
  